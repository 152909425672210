/* eslint-disable valid-jsdoc */
'use strict';
var isMobile = !($(window).width() >= 768);
var lottie = require('lottie-web');
var $jsonss = $('#loaderAnimation').data('loader');
// var bodymovin = require('bodymovin');

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function isElementInView(element, fullyInView) {
//     var pageTop = $(window).scrollTop();
//     var pageBottom = pageTop + $(window).height();
//     var elementTop = $(element).offset().top;
//     var elementBottom = elementTop + $(element).height();

//     if (fullyInView === true) {
//         return pageTop < elementTop && pageBottom > elementBottom;
//         // eslint-disable-next-line no-else-return
//     } else {
//         return elementTop <= pageBottom && elementBottom >= pageTop;
//     }
// }

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function renderCircleAnimation($curr, $path, $p, $total) {
//     $curr += 1;

//     if ($curr <= $total) {
//         var $circumfrence = isMobile ? 250.1 : 295.1;
//         $path.css(
//             'stroke-dasharray',
//             ($circumfrence / 100) * $curr + ', ' + $circumfrence
//         );
//         if ($curr === 1) {
//             $path.css('opacity', '1');
//             $path.attr('transform', 'rotate(-90 57.5 57.5)');
//         }
//         if ($curr > 0 && $curr < 30) {
//             $path.addClass('start-campaign');
//         } else if ($curr > 29 && $curr < 60) {
//             $path.addClass('medium-campaign');
//         } else if ($curr > 59) {
//             $path.addClass('closing-campaign');
//         }
//         $p.html($curr + '%');
//         window.requestAnimationFrame(function () {
//             renderCircleAnimation($curr, $path, $p, $total);
//         });
//     }
// }

// /**
//  * loadCircles.
//  *  @$circles {number} num1 The first number.
//  * @constructor
//  */
// function loadCircles($circles) {
//     $circles.each(function ($int, elem) {
//         var $elem = $(elem);
//         var $total = $elem.data('circle');
//         var $path = $elem.find('circle');
//         var $p = $elem.find('p');
//         renderCircleAnimation($total - 1, $path, $p, $total);
//     });
// }

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function renderCircle(elem) {
//     var $curr = 0;
//     var $elem = $(elem);
//     var $total = $elem.data('circle');
//     var $path = $elem.find('circle');
//     var $p = $elem.find('p');

//     window.requestAnimationFrame(function () {
//         renderCircleAnimation($curr, $path, $p, $total);
//     });
// }

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function checkCircles($loadingCircle) {
//     $loadingCircle.each(function () {
//         var $this = $(this);
//         if (isElementInView(this, true)) {
//             renderCircle(this);
//             $loadingCircle = $loadingCircle.not($this);
//         }
//     });

//     if ($loadingCircle.length) {
//         setTimeout(function () {
//             checkCircles($loadingCircle);
//         }, 40);
//     }
// }

// /**
//  * loadingCircle
//  * @constructor
//  */
// function loadingCircle() {
//     // var loadingCircle = $('.filter-block .slick-slide:not(.slick-cloned) .loading-circle');
//     var loadingCircles = $('.loading-circle');
//     if (loadingCircles.length) {
//         checkCircles(loadingCircles);
//         loadCircles(loadingCircles);
//     }
// }

// /**
//  * fucntion used to initate the dial on product tiles
//  */
// function initDial() {
//     $('.js-dial').each(function () {
//         var $dial = $(this);

//         var sold = Math.floor($dial.data('sold'));
//         var allocation = Math.floor($dial.data('max'));
//         var percentage = (sold / allocation) * 100;
//         var color;
//         switch (true) {
//             case (percentage < 30):
//                 color = '#61c652';
//                 break;
//             case (percentage > 29 && percentage < 60):
//                 color = '#ffc700';
//                 break;
//             default:
//                 color = '#ff6262';
//         }
//         $dial.knob({
//             fgColor: color,
//             inputColor: '#000',
//             format: function () {
//                 return sold + '/' + allocation;
//             }
//         });
//         $dial.removeClass('d-none');
//         $('.js-cart-dial').removeClass('d-none');
//     });
// }

// ===========================================================================================
// -------------------------------------------------------------------------------------------

// /**
//  * loadingCircle
//  * @constructor
//  */
// function loadingCircleNew() {
//     // var loadingCircle = $('.filter-block .slick-slide:not(.slick-cloned) .loading-circle');
//     var loadingCircles = $('.newLoadingCircle');
//     if (loadingCircles.length) {
//         checkCirclesNew(loadingCircles);
//         loadCirclesNew(loadingCircles);
//     }
// }

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function checkCirclesNew($loadingCircle) {
//     $loadingCircle.each(function () {
//         var $this = $(this);
//         if (isElementInView(this, true)) {
//             renderCircleNew(this);
//             $loadingCircle = $loadingCircle.not($this);
//         }
//     });

//     if ($loadingCircle.length) {
//         setTimeout(function () {
//             checkCirclesNew($loadingCircle);
//         }, 40);
//     }
// }


// /**
//  * loadCircles.
//  *  @$circles {number} num1 The first number.
//  * @constructor
//  */
// function loadCirclesNew($circles) {
//     $circles.each(function ($int, elem) {
//         var $elem = $(elem);
//         var $total = $elem.data('circle');
//         var $path = $elem.find('circle');
//         var $p = $elem.find('p');
//         renderCircleAnimationNew($total - 1, $path, $p, $total);
//     });
// }

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function renderCircleNew(elem) {
//     var $curr = 0;
//     var $elem = $(elem);
//     var $total = $elem.data('circle');
//     var $path = $elem.find('#newRect');
//     var $p = $elem.find('p');

//     window.requestAnimationFrame(function () {
//         renderCircleAnimationNew($curr, $path, $p, $total);
//     });
// }

// /**
//  * Showing Register screen.
//  * @constructor
//  */
// function renderCircleAnimationNew($curr, $path, $p, $total) {
//     $curr += 1;

//     if ($curr <= $total) {
//         var $circumfrence = 250;

//         $path.css(
//             'stroke-dasharray',
//             ($circumfrence / 100) * $curr + ', ' + $circumfrence
//         );

//         if ($curr === 1) {
//             $path.css('opacity', '1');
//             // $path.attr('transform', 'rotate(-90 57.5 57.5)');
//         }
//         if ($curr > 0 && $curr < 30) {
//             $path.addClass('start-campaign');
//         } else if ($curr > 29 && $curr < 60) {
//             $path.addClass('medium-campaign');
//         } else if ($curr > 59) {
//             $path.addClass('closing-campaign');
//         }
//         $p.html($curr + '%');
//         window.requestAnimationFrame(function () {
//             renderCircleAnimationNew($curr, $path, $p, $total);
//         });
//     }
// }

$(document).ready(function () {
    // initDial();
    if ($('.detail-main-wrapper-new').length) {
        var $lot = $('.newLoadingCircle');
        var $sold = $lot.data('itemsold');
        var $allot = $lot.data('itemallocated');
        var $id = $lot.data('newid');

        var animations = lottie.loadAnimation({
            container: document.getElementById($id), // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: $jsonss, // the path to the animation json
            name: 'textAnim'
        });
        var colorPer = Math.round(($sold / $allot) * 100);
        var $per = Math.round((240 / 100) * colorPer);

        if (colorPer >= 0 && colorPer < 30) {
            $lot.addClass('start-campaign');
        } else if (colorPer > 29 && colorPer < 60) {
            $lot.addClass('medium-campaign');
        } else if (colorPer > 59) {
            $lot.addClass('closing-campaign');
        }
        animations.goToAndStop($per, true);
    } else if ($('.product-detail-page').length) {
        var $lots = $('.newLoadingCircle');
        var $solds = $lots.data('itemsold');
        var $allots = $lots.data('itemallocated');
        var $ids = $lots.data('newid');

        var animationss = lottie.loadAnimation({
            container: document.getElementById($ids), // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: $jsonss, // the path to the animation json
            name: 'textAnim'
        });
        var colorPers = Math.round(($solds / $allots) * 100);
        var $pers = Math.round((120 / 100) * colorPers);

        if (colorPers >= 0 && colorPers < 30) {
            $lots.addClass('start-campaign');
        } else if (colorPers > 29 && colorPers < 60) {
            $lots.addClass('medium-campaign');
        } else if (colorPers > 59) {
            $lots.addClass('closing-campaign');
        }
        animationss.goToAndStop($pers, true);
    } else {
        // console.log('desktopCampaignBoxs');
        // $('.desktopCampaignBox').each(function (index) {
        //     var $this = $(this);
        //     var $lotss = $this.find('.newLoadingCircle');
        //     var $soldss = $lotss.data('itemsold');
        //     var $allotss = $lotss.data('itemallocated');
        //     var $idss = $lotss.data('newid');

        //     var animation = lottie.loadAnimation({
        //         container: document.getElementById($idss), // the dom element that will contain the animation
        //         renderer: 'svg',
        //         loop: false,
        //         autoplay: false,
        //         path: $jsonss, // the path to the animation json
        //         name: 'textAnim'
        //     });
        //     var colorPerss = Math.round(($soldss / $allotss) * 100);
        //     var $perss = Math.round((120 / 100) * colorPerss);

        //     if (colorPerss > 0 && colorPerss < 30) {
        //         $lotss.addClass('start-campaign');
        //     } else if (colorPerss > 29 && colorPerss < 60) {
        //         $lotss.addClass('medium-campaign');
        //     } else if (colorPerss > 59) {
        //         $lotss.addClass('closing-campaign');
        //     }
        //     animation.goToAndStop($perss, true);
        // });
    }

    $('body').on('live:initDial', function (e) {
        if (isMobile) {
            $('.idealz-credit-campaign-box').each(function (index) {
                var $this = $(this);
                var $lot = $this.find('.newLoadingCircle');
                var $sold = $lot.data('itemsold');
                var $allot = $lot.data('itemallocated');
                var $id = $lot.data('newid');

                var animation = lottie.loadAnimation({
                    container: document.getElementById($id), // the dom element that will contain the animation
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: $jsonss, // the path to the animation json
                    name: 'textAnim'
                });
                var colorPer = Math.round(($sold / $allot) * 100);
                var $per = Math.round((240 / 100) * colorPer);

                if (colorPer >= 0 && colorPer < 30) {
                    $lot.addClass('start-campaign');
                } else if (colorPer > 29 && colorPer < 60) {
                    $lot.addClass('medium-campaign');
                } else if (colorPer > 59) {
                    $lot.addClass('closing-campaign');
                }
                animation.goToAndStop($per, true);
            });

            $('.new-allocation-progress').each(function () {
                var $this = $(this);
                var $lot = $this.find('.new-allocation-progress-bar');
                var $sold = $this.data('itemsold');
                var $allot = $this.data('itemallocated');

                var colorPer = Math.round(($sold / $allot) * 100);

                if (colorPer > 0 && colorPer < 30) {
                    $lot.addClass('start-campaign');
                } else if (colorPer > 29 && colorPer < 60) {
                    $lot.addClass('medium-campaign');
                } else if (colorPer > 59) {
                    $lot.addClass('closing-campaign');
                }
            });
        } else {
            $('.desktopCampaignBox').each(function (index) {
                var $this = $(this);
                var $lotaa = $this.find('.newLoadingCircle');
                var $soldaa = $lotaa.data('itemsold');
                var $allotaa = $lotaa.data('itemallocated');
                var $idaa = $lotaa.data('newid');
            

                var animation = lottie.loadAnimation({
                    container: document.getElementById($idaa), // the dom element that will contain the animation
                    renderer: 'svg',
                    loop: false,
                    autoplay: false,
                    path: $jsonss, // the path to the animation json
                    name: 'textAnim'
                });
                var colorPeraa = Math.round(($soldaa / $allotaa) * 100);
                var $peraa = Math.round((120 / 100) * colorPeraa);

                if (colorPeraa >= 0 && colorPeraa < 30) {
                    $lotaa.addClass('start-campaign');
                } else if (colorPeraa > 29 && colorPeraa < 60) {
                    $lotaa.addClass('medium-campaign');
                } else if (colorPeraa > 59) {
                    $lotaa.addClass('closing-campaign');
                }
                animation.goToAndStop($peraa, true);
            });
        }
    });
});

$('body').on('quickView:init', function (e) {
    $('.product-quickview').each(function () {
        var $this = $(this);
        var $lot = $this.find('.newLoadingCircle');
        var $sold = $lot.data('itemsold');
        var $allot = $lot.data('itemallocated');
        var $id = $lot.data('newid');

        var animations = lottie.loadAnimation({
            container: document.getElementById($id), // the dom element that will contain the animation
            renderer: 'svg',
            loop: false,
            autoplay: false,
            path: $jsonss, // the path to the animation json
            name: 'textAnim'
        });
        
        var colorPer = Math.round(($sold / $allot) * 100);
        var $per = Math.round((120 / 100) * colorPer);
        

        if (colorPer >= 0 && colorPer < 30) {
            $lot.addClass('start-campaign');
        } else if (colorPer > 29 && colorPer < 60) {
            $lot.addClass('medium-campaign');
        } else if (colorPer > 59) {
            $lot.addClass('closing-campaign');
        }
        animations.goToAndStop($per, true);
    });
});

$('body').on('liveDetail:initDial', function (e) {
    var elem = $('#quickViewModal .product-quickview');
    var $curr = 0;
    var $indi = elem.find('.item-indicator');
    var $total = $indi.data('circle');

    if ($curr <= $total) {
        if ($total === 1) {
            $indi.css('opacity', '1');
            $indi.attr('transform', 'rotate(-90 57.5 57.5)');
        }
        if ($total >= 0 && $total < 30) {
            $indi.addClass('start-campaign');
        } else if ($total > 29 && $total < 60) {
            $indi.addClass('medium-campaign');
        } else if ($total > 59) {
            $indi.addClass('closing-campaign');
            // eslint-disable-next-line no-unused-expressions
            $('.closing-soon__item').length ? $indi.addClass('blink') : '';
        }
        $indi.css({ width: $total + '%' });
    } else if ($curr === 101) {
        $indi.next().css('opacity', '0');
    }
});
