module.exports = {
    init: function () {
        // eslint-disable-next-line consistent-return
        $('#registration-form-phoneMobile,.js-phone-mobile, #registration-form-verification-code').on('keydown', function (e) {
            var $test = e.originalEvent.data ? e.originalEvent.data.charCodeAt(0) : e.originalEvent.which;
            // Allow only backspace and delete
            if ($test === 127 || $test === 8 || $test === 0 || $test === 9) {
                // let it happen, don't do anything
            } else {
                // Ensure that it is a number and stop the keypress
                // eslint-disable-next-line no-lonely-if
                if (e.shiftKey || !(($test >= 48 && $test <= 57) || ($test >= 96 && $test <= 105))) {
                    e.preventDefault();
                    return false;
                }
            }
        });
    }
};
