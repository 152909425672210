/* eslint-disable no-undef */
'use_strict';

var isMobile = !($(window).width() >= 768);
var toastr = require('../components/toastr');

// eslint-disable-next-line valid-jsdoc
/**
 * function used to get the active payment button
 * @returns jQuery{Object}
 */
function getActivePaymentButton() {
    var activePaymentButton = null;
    if ($('.js-payment-section-buttons').is(':visible')) {
        activePaymentButton = $('.js-payment-section-buttons').find('.desktop-button:not(.d-none)');
    }

    return activePaymentButton;
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
    if (data.valid.error) {
        if (data.valid.message) {
            toastr(data.valid.message);
        } else {
            toastr(data.resources.emptyCartMsg);

            $('.number-of-items').empty().append(data.resources.numberOfItems);
            $('.minicart-quantity').empty().append(data.numItems);
            $('.minicart-link').attr({
                'aria-label': data.resources.minicartCountOfItems,
                title: data.resources.minicartCountOfItems
            });
            $('.minicart .popover').empty();
            $('.minicart .popover').removeClass('show');
        }

        $('.checkout-btn').addClass('disabled');
    } else {
        $('.checkout-btn').removeClass('disabled');
    }
}

// /**
//  * Updates the size selection option of a line item
//  * @param {Object} data - AJAX response from the server
//  * @param {string} uuid - The uuid of the product line item to update
//  */
// function updateSizeSelection(data, uuid) {
//     var lineItem;
//     var messages = '';

//     for (var s = 0; s < data.items.length; s++) {
//         if (data.items[s].UUID === uuid) {
//             lineItem = data.items[s];
//             break;
//         }
//     }
//     $('.js-sizes-module-' + uuid).empty();
//     if (lineItem.availableSizes.length > 0 && lineItem.deliveryOption && lineItem.deliveryOption.toLowerCase() !== 'donated') {
//         messages = '';
//         messages += '<div class="sizes-module">';
//         messages += '<div class="size-list">';
//         messages += '<div>';
//         for (var b = 0; b < lineItem.availableSizes[0].values.length; b++) {
//             var selectedClass = lineItem.availableSizes[0].selectedSize !== null && lineItem.availableSizes[0].selectedSize === lineItem.availableSizes[0].values[b].value ? 'active' : '';
//             messages += '<a href="javascript:;" class="' + selectedClass + '" data-value="' + lineItem.availableSizes[0].values[b].value + '">' + lineItem.availableSizes[0].values[b].value + '</a>';
//         }
//         messages += '</div></div></div>';
//         $('.js-sizes-module-' + uuid).html(messages);
//     }
// }

// /**
//  * Updates the size selection option of a line item
//  * @param {Object} data - AJAX response from the server
//  * @param {string} uuid - The uuid of the product line item to update
//  */
// function updateSizeSelectionForCart(data) {
//     var lineItem;
//     var messages = '';

//     for (var s = 0; s < data.items.length; s++) {
//         lineItem = data.items[s];
//         var uuid = lineItem.UUID;
//         $('.js-sizes-module-' + uuid).empty();
//         if (lineItem.availableSizes.length > 0 && lineItem.deliveryOption && lineItem.deliveryOption.toLowerCase() !== 'donated') {
//             messages = '';
//             messages += '<div class="sizes-module">';
//             messages += '<div class="size-list">';
//             messages += '<div>';
//             for (var b = 0; b < lineItem.availableSizes[0].values.length; b++) {
//                 var selectedClass = lineItem.availableSizes[0].selectedSize !== null && lineItem.availableSizes[0].selectedSize === lineItem.availableSizes[0].values[b].value ? 'active' : '';
//                 messages += '<a href="javascript:;" class="' + selectedClass + '" data-value="' + lineItem.availableSizes[0].values[b].value + '">' + lineItem.availableSizes[0].values[b].value + '</a>';
//             }
//             messages += '</div></div></div>';
//             $('.js-sizes-module-' + uuid).html(messages);
//         }
//     }
// }

// /**
//  * function used to handle coupon code application on the frontend
//  * @param {Object} data - AJAX response from the server
//  */
// function handleCouponCode(data) {
//     // update UI with coupon code applied UI
//     var couponData = [];
//     for (var i = 0; i < data.totals.discounts.length; i++) {
//         if (data.totals.discounts[i].type === 'coupon') {
//             couponData.push({
//                 couponCode: data.totals.discounts[i].couponCode,
//                 UUID: data.totals.discounts[i].UUID
//             });
//         }
//     }

//     if (couponData.length > 0) {
//         // coupon applied
//         $('.js-cart-coupon-applied-area').empty();
//         var cloneTemplate = $('.js-sample-ui-applied-template').clone();
//         $(cloneTemplate).find('.js-applied-code').text(couponData[0].couponCode);
//         $(cloneTemplate).find('.remove-coupon').attr('data-code', couponData[0].couponCode);
//         $(cloneTemplate).find('.remove-coupon').attr('data-uuid', couponData[0].UUID);
//         $(cloneTemplate).removeClass('d-none');
//         cloneTemplate.appendTo('.js-cart-coupon-applied-area');
//     } else {
//         // coupon is not applied
//         $('#promo-box-ui').removeClass('d-none');
//         $('.js-cart-coupon-applied-area').empty();
//     }
// }

/**
 * function used to update the cart qty after aja call
 * @param {Object} data - AJAX response from the server
 */
function handleCartTicketQty(data) {
    if (!data) {
        return;
    }
    var items = data.items;
    if (items.length) {
        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            var classToSearch = '.uuid-' + item.UUID;
            var $elm = $(classToSearch);
            if ($elm.length) {
                $elm.find('.campaign-ticket-count .numberoftickets').html(item.ticketCount);
            }
        }
    }
}


/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    if (!data) {
        return;
    }
    // handleCouponCode(data);
    handleCartTicketQty(data);

    // logic to update the ipoint redeeming balance on store front
    var totals = data.totals;
    var walletSummary = data.walletPaymentInstruments;

    if (totals && walletSummary) {
        var creditAvailable = walletSummary[0].creditValue.formattedValue;
        var cartTotal = totals.applyCreditAmount.formatted;
        var remainingCredit = totals.remainingIpoints;
        $('.js-applied-ipoints').html(cartTotal);
        $('.js-available-ipoints').html(creditAvailable);
        $('.js-remaining-ipoints').html(remainingCredit);
    }
    if (data.isFullyPaidViaCredit) {
        $('.js-continue-to-payment').removeClass('is-disabled');
        $('.notEnoughIpoints').hide();
        $('.not-enough-ipoints').hide();
        $('.js-purchase-allowed').removeClass('hide');
        $('.js-purchase-not-allowed').addClass('hide');
    } else {
        $('.js-continue-to-payment').addClass('is-disabled');
        $('.js-purchase-allowed').addClass('hide');
        $('.js-purchase-not-allowed').removeClass('hide');
        $('.js-remaining-ipoints').html(totals.creditsNeeded);
        if (window.showInsufficientPopup) {
            $('.notEnoughIpoints').show();
            $('.not-enough-ipoints').show();
        }
    }

    $('.progress-indicator').css({ width: data.applyCreditPercentage + '%' });

    // logic to check if bundle needs to be rendered again
    // $('.js-bundle-promo-render').html(data.bundleSuggestion);
    // $('.js-suggestion-render').html(data.cartSuggestion);
}

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    $('.availability-' + lineItem.UUID).empty();

    if (lineItem.availability) {
        if (lineItem.availability.messages) {
            lineItem.availability.messages.forEach(function (message) {
                messages += '<p class="line-item-attributes">' + message + '</p>';
            });
        }

        if (lineItem.availability.inStockDate) {
            messages += '<p class="line-item-attributes line-item-instock-date">'
                + lineItem.availability.inStockDate
                + '</p>';
        }
    }

    $('.availability-' + lineItem.UUID).html(messages);
}

/**
 * Finds an element in the array that matches search parameter
 * @param {array} array - array of items to search
 * @param {function} match - function that takes an element and returns a boolean indicating if the match is made
 * @returns {Object|null} - returns an element of the array that matched the query.
 */
function findItem(array, match) {
    for (var i = 0, l = array.length; i < l; i++) {
        if (match.call(this, array[i])) {
            return array[i];
        }
    }
    return null;
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var lineItem = findItem(data.cartModel.items, function (item) {
        return item.UUID === uuid;
    });

    if (lineItem.variationAttributes) {
        var colorAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'color';
        });

        if (colorAttr) {
            var colorSelector = '.Color-' + uuid;
            var newColor = 'Color: ' + colorAttr.displayValue;
            $(colorSelector).text(newColor);
        }

        var sizeAttr = findItem(lineItem.variationAttributes, function (attr) {
            return attr.attributeId === 'size';
        });

        if (sizeAttr) {
            var sizeSelector = '.Size-' + uuid;
            var newSize = 'Size: ' + sizeAttr.displayValue;
            $(sizeSelector).text(newSize);
        }

        var imageSelector = '.card.product-info.uuid-' + uuid + ' .item-image > img';
        $(imageSelector).attr('src', lineItem.images.prize[0].url);
        $(imageSelector).attr('alt', lineItem.images.prize[0].alt);
        $(imageSelector).attr('title', lineItem.images.prize[0].title);
    }

    var qtySelector = '.quantity[data-uuid="' + uuid + '"]';
    $(qtySelector).val(lineItem.quantity);
    $(qtySelector).data('pid', data.newProductId);

    $('.remove-product[data-uuid="' + uuid + '"]').data('pid', data.newProductId);

    var priceSelector = '.line-item-price-' + uuid + ' .sales .value';
    $(priceSelector).text(lineItem.price.sales.formatted);
    $(priceSelector).attr('content', lineItem.price.sales.decimalPrice);

    if (lineItem.price.list) {
        var listPriceSelector = '.line-item-price-' + uuid + ' .list .value';
        $(listPriceSelector).text(lineItem.price.list.formatted);
        $(listPriceSelector).attr('content', lineItem.price.list.decimalPrice);
    }
}

/**
 * Shows/Hides Free ticket icons
 * @param {boolean} donated if donated
 */
function handleFreeTicketIcons(donated) {
    var mobileFreeTickets = $('.campaign-ticket-count:not(".non-donateable")');
    var desktopFreeTickets = $('.item-block:not(".donation-with-points") .ticket-number:not(".non-donateable")');
    var desktopFreeTicketsPrint = $('.item-block:not(".donation-with-points") .per-unit');
    var desktopFreeTicketsPrintIpoints = $('.item-block.donation-with-points .per-unit');
    if (mobileFreeTickets.length) {
        if (donated) {
            mobileFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    $this.find('.number_tickets .numberoftickets').html(ticketCount);
                } else {
                    $this.find('.number_tickets .numberoftickets').html((ticketCount + 1));
                }
            });
        } else {
            mobileFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    $this.find('.number_tickets .numberoftickets').html((ticketCount - 1));
                }
            });
        }
    } else {
        // eslint-disable-next-line no-lonely-if
        if (donated) {
            desktopFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    if (ticketCount === 2) {
                        // $this.closest('.earlybird-interaction .js-free-tickets-icon').removeClass('d-none');
                        $this.html(ticketCount + ' ' + resources.tickets);
                        desktopFreeTicketsPrint.html(resources.perUnitDonated);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitDonated);
                    } else {
                        $this.html(ticketCount + ' ' + resources.tickets);
                        desktopFreeTicketsPrint.html(resources.perUnitDonated);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitDonated);
                    }
                }
            });
            // freeTicketIcons.removeClass('d-none');
        } else {
            desktopFreeTickets.each(function () {
                var $this = $(this);
                var ticketCount = $this.data('ticket-count');
                if (ticketCount !== 1) {
                    if (ticketCount === 2) {
                        // $this.closest('.earlybird-interaction .js-free-tickets-icon').addClass('d-none');
                        $this.html((ticketCount - 1) + ' ' + resources.ticket);
                        desktopFreeTicketsPrint.html(resources.perUnitDouble);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitIpoints);
                    } else {
                        $this.html((ticketCount - 1) + ' ' + resources.tickets);
                        desktopFreeTicketsPrint.html(resources.perUnitDouble);
                        desktopFreeTicketsPrintIpoints.html(resources.perUnitIpoints);
                    }
                }
            });
        }
    }
}

/**
 * Updates product and ticket quantities
 * @param {jQuery} $donateContext donation section
 * @param {Object} basket basket model returned from ajax
 */
function handleTicketQuantity($donateContext, basket) {
    var ticketQuantity = $('.js-ticket-quantity', $donateContext);
    ticketQuantity.html(basket.ticketQuantity);
}

// eslint-disable-next-line valid-jsdoc
/**
 * UpdateTotals ifremoved from quick view modal within cart page
 */
function updatePostTotal(basket) {
    updateCartTotals(basket);
}

module.exports = {
    updateCartTotals: updateCartTotals,
    validateBasket: validateBasket,
    updateAvailability: updateAvailability,
    updateProductDetails: updateProductDetails,
    handleFreeTicketIcons: handleFreeTicketIcons,
    handleTicketQuantity: handleTicketQuantity,
    updatePostTotal: updatePostTotal,
    getActivePaymentButton: getActivePaymentButton
};
