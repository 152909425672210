'use strict';

$('.miniaccount').on('mouseenter focusin touchstart', function () {
    if ($('.search:visible').length === 0) {
        return;
    }

    if ($('.miniaccount .popover.show').length === 0) {
        $('.miniaccount .popover').addClass('show');
    }
});

$('body').on('touchstart click', function (e) {
    if ($('.miniaccount').has(e.target).length <= 0) {
        $('.miniaccount .popover').removeClass('show');
    }
});

$('.miniaccount').on('mouseleave focusout', function (event) {
    if ((event.type === 'focusout')
        || (event.type === 'mouseleave' && $(event.target).is('.miniaccount .quantity'))
        || $('body').hasClass('modal-open')) {
        event.stopPropagation();
        return;
    }
    $('.miniaccount .popover').removeClass('show');
});

