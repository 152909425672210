'use strict';
window.showInsufficientPopup = true;
var base = require('base/product/base');
var focusHelper = require('base/components/focus');
var isMobile = !($(window).width() >= 768);
var toastr = require('../components/toastr');
var lottie = require('lottie-web');
const helper = require('./helper');
var CUSTOM_MOENGAGE = require('../moengage');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
    var newUrl = url;
    newUrl += (newUrl.indexOf('?') !== -1 ? '&' : '?') + Object.keys(params).map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
    }).join('&');

    return newUrl;
}

/**
 * function used to check if the size is selected or not if visible
 */
function checkIfSizeIsSelected() {
    var sizeSelected = true;
    if (!isMobile) {
       $('.size-selection-wrapper-js:visible .sizes-module').each(function( index ) {
            if ($(this).find('.size-list a.active').length === 0) {
                sizeSelected = false;
            }
        });
    }

    return sizeSelected;
}


/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
    toastr(message);
}


/**
 * replace content of modal
 * @param {string} actionUrl - url to be used to remove product
 * @param {string} productID - pid
 * @param {string} productName - product name
 * @param {string} uuid - uuid
 * @param {string} tagEvent - google analytics tag event name
 * @param {string} tagParam - google analytics tag params
 */
function confirmDelete(actionUrl, productID, productName, uuid, tagEvent, tagParam) {
    var $deleteConfirmBtn = $('.cart-delete-confirmation-btn');
    var $productToRemoveSpan = $('.product-to-remove');

    $deleteConfirmBtn.data('pid', productID);
    $deleteConfirmBtn.data('action', actionUrl);
    $deleteConfirmBtn.data('uuid', uuid);

    $deleteConfirmBtn.data('gtag-event', tagEvent);
    $deleteConfirmBtn.data('gtag-param', tagParam);

    $productToRemoveSpan.empty().append(productName);
}

/**
 * Go to forgot password
 * @constructor
 */
function mobileOtpFill() {
    $('.dummy').keyup(function (event) {
        var len = $('.mobile-CVV.active .dummy').val().toString().length;
        var act = $('.mobile-CVV.active .dummy').parent().find('.cvc-number');
        var accumulatedValue = '';

        if (len === 1) {
            $(this).next().focus();
        }
        if ((event.which === 8 || event.which === 46)) {
            $(this).prev('input').focus();
        }
        // eslint-disable-next-line consistent-return
        $('.mobile-CVV.active .dummy').each(function () {
            var $trim = $(this).val();
            $trim = $.trim($trim);
            accumulatedValue += $trim;
            if ($trim.length !== 0) {
                $('#submitPaymentCartBtn').prop('disabled', false);
                $('#submitPaymentCartBtn').removeClass('is-disabled');
                act.val(accumulatedValue);
            } else {
                $('#submitPaymentCartBtn').prop('disabled', true);
                $('#submitPaymentCartBtn').addClass('is-disabled');
                return false;
            }
        });
    });
}

module.exports = function () {
    $('body').on('cart:quickViewMinus', function (data) {
        helper.updatePostTotal(data.basket);
    });

    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();
        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        var tagEvent = $(this).data('gtag-event');
        var tagParam = $(this).data('gtag-param');
        confirmDelete(actionUrl, productID, productName, uuid, tagEvent, tagParam);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.optional-promo', function (e) {
        e.preventDefault();
        $('.js-promo-box:not(.shippingPage)').toggle();
        $(this).addClass('unvisible');
    });

    $('body').on('click', '.jsRemoveCartItem', function (e) {
        e.preventDefault();
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            uuid: uuid
        };


        var page = 'home';
        if ($('.page').attr('data-action') === 'Home-Show') {
            page = 'Home';
        } else if ($('.page').attr('data-action') === 'Product-Show') {
            page = 'L2';
        } else if ($('.page').attr('data-action') === 'Cart-Show') {
            page = 'Cart';
        } else if ($('.page').attr('data-action') === 'Wishlist-Show') {
            page = 'Wishlist';
        }

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        // $.spinner().start();
        if (isMobile) {
            $('.js-modal-confirm-delete').addClass('visible');
            $('.keepit').unbind('click');
            $('.yesremove').unbind('click');
            $('.keepit').click(function () {
                $('.js-modal-confirm-delete').removeClass('visible');
            });
            $('.yesremove').click(function () {
                $('.js-modal-confirm-delete').removeClass('visible');
                $.spinner().start();
                $.ajax({
                    url: url,
                    type: 'get',
                    dataType: 'json',
                    success: function (data) {
                        $('body').trigger('cart:update', data.basket);
                        $('body').trigger('GTM:removeCart', data);
                        $(document).trigger('cart:removeItem', {
                            pid: productID
                        });

                        window.location.reload();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                });
            });
        } else {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                success: function (data) {
                    $(document).trigger('cart:removeItem', {
                        pid: productID
                    });
                    $('body').trigger('GTM:removeCart', data);
                    $('body').trigger('cart:update', data.basket);
                    window.location.reload();
                },
                error: function (err) {
                    $.spinner().stop();
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                    }
                }
            });
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function (e) {
        // window.showInsufficientPopup = true;
        var preSelectQty = $(this).data('pre-select-qty');
        // var quantity = $(this).val();
        var quantity = $(this).attr('data-updatedqty')

        // check if the call is to add or to remove
        // if (parseInt(preSelectQty) > parseInt(quantity)) {
        //     window.showInsufficientPopup = false;
        // }
        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var currentScope = $(this);
        var $productDom = $('.uuid-' + uuid);
        // var selectedQty = parseInt($productDom.find('.quantity-form input').val());
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid,
            page: 'cart'
        };
        url = appendToUrl(url, urlParams);

        // $(this).parents('.card').spinner().start();
        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                if (data.error) {
                    $productDom.find('.quantity-form input').val(preSelectQty);
                    createErrorNotification(data.message);
                    $(currentScope).val(preSelectQty);
                    $.spinner().stop();
                    return;
                }
                // // $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                // $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                // helper.updateCartTotals(data);
                // helper.validateBasket(data);
                // $(this).data('pre-select-qty', quantity);

                // $('body').trigger('cart:update', data);

                // // update gtm data
                // var gtagElems = $('span[data-gtag-event="begin_checkout"]');
                // if (data && 'gtags' in data) {
                //     gtagElems.attr('data-gtag-param', data.gtags[0].param);
                //     $('body').trigger('GTM:cartUpdate', data);
                // }

                // if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                //     location.reload();
                // } else {
                //     $.spinner().stop();
                // }

                // $('body').trigger('GTM:globalCartAction', data);
                window.location.reload();

            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $(document).on('click', '.js-closepromopop', function (e) {
        e.preventDefault();
        $('.js-promo-box').hide();
        $('.add_promo_code').removeClass('unvisible');
    });

    $('body').on('product:updateAddToCart', function (e, response) {
        // update global add to cart (single products, bundles)
        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        $('.update-cart-product-global', dialog).attr('disabled',
            !$('.global-availability', dialog).data('ready-to-order')
            || !$('.global-availability', dialog).data('available')
        );
    });

    $('body').on('product:updateAvailability', function (e, response) {
        // bundle individual products
        $('.product-availability', response.$productContainer)
            .data('ready-to-order', response.product.readyToOrder)
            .data('available', response.product.available)
            .find('.availability-msg')
            .empty()
            .html(response.message);


        var dialog = $(response.$productContainer)
            .closest('.quick-view-dialog');

        if ($('.product-availability', dialog).length) {
            // bundle all products
            var allAvailable = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('available'); });

            var allReady = $('.product-availability', dialog).toArray()
                .every(function (item) { return $(item).data('ready-to-order'); });

            $('.global-availability', dialog)
                .data('ready-to-order', allReady)
                .data('available', allAvailable);

            $('.global-availability .availability-msg', dialog).empty()
                .html(allReady ? response.message : response.resources.info_selectforstock);
        } else {
            // single product
            $('.global-availability', dialog)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);
        }
    });

    $('body').on('product:afterAttributeSelect', function (e, response) {
        if ($('.modal.show .product-quickview .bundle-items').length) {
            $('.modal.show').find(response.container).data('pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else {
            $('.modal.show .product-quickview').data('pid', response.data.product.id);
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('selected-quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();

        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var selectedQuantity = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('selected-quantity');
        var uuid = $(this).closest('.cart-and-ipay').find('.update-cart-url').data('uuid');
        var form = {
            uuid: uuid,
            pid: base.getPidValue($(this)),
            quantity: selectedQuantity
        };
        $(this).parents('.card').spinner().start();
        if (updateProductUrl) {
            $.ajax({
                url: updateProductUrl,
                type: 'post',
                context: this,
                data: form,
                dataType: 'json',
                success: function (data) {
                    $('#editProductModal').modal('hide');

                    $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                    helper.updateCartTotals(data.cartModel);

                    if (data.uuidToBeDeleted) {
                        $('.uuid-' + data.uuidToBeDeleted).remove();
                    }

                    helper.validateBasket(data.cartModel);

                    $('body').trigger('cart:update');

                    $.spinner().stop();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.js-add-bundle-to-cart', function () {
        var bundlePids = [];
        var $this = $(this);
        var $parent = $('.bundle-container');
        $parent.find('.bundleLineItem-Box').each(function () {
            // try to add products individually
            var pid = $(this).attr('product-id');
            if (pid) {
                bundlePids.push(pid);
            }
        });

        if (bundlePids.length > 0) {
            $.spinner().start();
            var action = $('.bundleOfferSections').attr('data-action');
            $.ajax({
                url: action,
                type: 'post',
                data: { pids: JSON.stringify(bundlePids) },
                success: function (data) {
                    if (!data.error) {
                        $('body').trigger('cart:addTOCartGTM', $this);
                        $(this).addClass('added');
                        $(this).find('span').html(resources.addedItems);
                        var data = { event: $this.attr('data-gtag-add-event'), param: $this.attr('data-gtag-add-param') };
                        $.spinner().stop();
                        window.location.reload();
                    } else {
                        createErrorNotification(data.message);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.suggestion-container .add-toCart', function (e) {
        e.preventDefault();
        var suggestionPid = $(this).parents('.bundleLineItem-Box').attr('product-id');
        if (suggestionPid !== '') {
            var suggestionPids = [];
            suggestionPids.push(suggestionPid);
            var action = $(this).attr('data-action');
            var $this = $(this);
            $.spinner().start();
            $.ajax({
                url: action,
                type: 'post',
                data: { pids: JSON.stringify(suggestionPids) },
                success: function (data) {
                    if (!data.error) {
                        $.spinner().stop();
                        $('body').trigger('cart:addTOCartGTM', $this);
                        window.location.reload();
                    } else {
                        createErrorNotification(data.message);
                        $.spinner().stop();
                    }
                }
            });
        }
    });

    $('body').on('click', '.js-view-summary-section', function (e) {
        $(this).addClass('hide');
        $('#payment-new-summary-section').slideDown();
    });

    $('body').on('click', '.js-hide-summary-section', function (e) {
        $('#payment-new-summary-section').slideUp();
        $('.js-view-summary-section').removeClass('hide');
    });

    $('.js-proceed-checkout').on('click', function (e) {
        e.preventDefault();
        var href = $(this).attr('href');
        var couponCode = $('#couponCode').val();
        var orderTotal = $(this).attr('data-order-total');
        var gtagData = {
            promo_code: couponCode,
            order_total: orderTotal,
        }
        if (typeof Moengage !== 'undefined' && Moengage) {
            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.initiateCheckout, gtagData, href);
        } else {
            window.location.href = href;
        }
    });

    var $jsons = $('#lootieAnimDonate').data('url');
    lottie.loadAnimation({
        container: document.getElementById('lootieAnimDonate'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $jsons // the path to the animation json
    });

    var $jsonss = $('#lootieAnimSelf').data('url');
    lottie.loadAnimation({
        container: document.getElementById('lootieAnimSelf'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $jsonss // the path to the animation json
    });

    var $jsonsss = $('#lootieAnimStandard').data('url');
    lottie.loadAnimation({
        container: document.getElementById('lootieAnimStandard'), // the dom element that will contain the animation
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: $jsonsss // the path to the animation json
    });

    $(document).on('approaching:applied', function () {
        if ($('.successfullyApplied')) {
            setTimeout(function () {
                $('.successfullyApplied').addClass('d-none');
            }, 5000);
        }
    });

    $(document).scroll(function () {
        if (isMobile && $('.cart-page').length > 0) {
            if ($(window).scrollTop() > 100) {
                $('.withProgress').addClass('scrollingHead');
            }
            else if ($(window).scrollTop() <= 150) {
                $('.withProgress').removeClass('scrollingHead');
            }
        }
    });

    $(document).on('click', '.js-back-history', function (e) {
        e.preventDefault();
        history.back();
    });

    // logic to handle weekly campaign feature using toggle
    $(document).on('change', '.js-cart-weekly-module input', function (e) {
        e.preventDefault();
        $.spinner().start();
        if($(this).is(":checked")) {
            var action = $(this).data('action-add');
            $.ajax({
                url: action,
                type: 'get',
                success: function(data) {
                    window.location.href = data.redirectUrl;
                    $.spinner().stop();
                }
            });
        } else {
            var action = $(this).data('action-remove');
            $.ajax({
                url: action,
                type: 'get',
                success: function(data) {
                    window.location.href = data.redirectUrl;
                    $.spinner().stop();
                }
            });
        }
    });

    // logic to handle weekly campaign feature using toggle
    $(document).on('click', '.weekly-winner-wrapper .weeklyBtn', function (e) {
        e.preventDefault();
        $.spinner().start();
        var action = $(this).attr('href');
        $.ajax({
            url: action,
            type: 'get',
            success: function(data) {
                window.location.href = data.redirectUrl;
                $.spinner().stop();
            }
        });
    });

    $(document).on('click', '.js-donation-floater', function(e) {
        e.preventDefault();
        $('.donation-flyout').addClass('show');
        $('body').addClass('no-scroll');
    });

    $(document).on('click', '.js-donate-cart', function(e) {
        e.preventDefault();
        $('.js-donation-floater').remove();
        $('.donation-flyout').removeClass('show');
        $('body').removeClass('no-scroll');
        // $('.donation-options-box').addClass('show');
        var url = $(this).data('donate-url');
        var donateValue = $(this).data('donate');
        $.ajax({
            url: url,
            type: 'post',
            data: { donated: donateValue === 'yes' },
            success: function(data) {
                window.location.reload();
            }
        });
    });

    $(document).on('change', '.js-new-donate-check input', function(e) {
        e.preventDefault();
        $.spinner().start();
        var isDonated = $(this).prop('checked');

        // $('.donation-options-box').addClass('show');
        var url = $(this).parents('.js-new-donate-check').data('donate-url');
        $.ajax({
            url: url,
            type: 'post',
            data: { donated: isDonated },
            success: function() {
                window.location.reload();
            }
        });

    });

    $(document).on('click', '.js-no-donate-cart', function(e) {
        e.preventDefault();
        $('.donation-flyout').removeClass('show');
        $('body').removeClass('no-scroll');
    });

    $('body').on('submit', '.promo-code-form', function (e) {
        e.preventDefault();
        $.spinner().start();
        var $this = $(this);
        var $form = $('.promo-code-form');

            if (!$this.find('.coupon-code-field').val()) {
                $('.promo-code-form .form-control').addClass('is-invalid');
                $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
                $.spinner().stop();
                return false;
            }

            $('.promo-code-form .form-control').removeClass('is-invalid');
            // $('.coupon-error-message').empty();

            $.ajax({
                url: $form.attr('action'),
                type: 'GET',
                dataType: 'json',
                data: $form.serialize(),
                success: function (data) {
                    $.spinner().stop();
                    if (data.error) {
                        toastr(data, data.errorMessage);
                    } else if (typeof Moengage !== 'undefined' && Moengage) {
                        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeAdded, {coupon_code: $('#couponCode').val()}, window.location.href);
                    } else {
                        window.location.reload();
                    }
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        createErrorNotification(err.errorMessage);
                        if (typeof Moengage !== 'undefined' && Moengage) {
                            CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeFailed, {coupon_code: $('#couponCode').val(), error: err.errorMessage});
                        }
                        $.spinner().stop();
                    }
                }
            });

        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');
        var urlParams = {
            code: couponCode,
            uuid: uuid
        };

        url = appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (typeof Moengage !== 'undefined' && Moengage) {
                    CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeRemoved, {coupon_code: couponCode}, window.location.href);
                } else {
                    window.location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    createErrorNotification(err.responseJSON.errorMessage);
                    if (typeof Moengage !== 'undefined' && Moengage) {
                        CUSTOM_MOENGAGE.trackUserEvents(CUSTOM_MOENGAGE.MOENGAGE_EVENT_NAME.promoCodeFailed, {coupon_code: couponCode, error: err.errorMessage});
                    }
                    $.spinner().stop();
                }
            }
        });
    });

    mobileOtpFill();

    base.selectAttribute();
    base.colorAttribute();
    base.removeBonusProduct();
    base.selectBonusProduct();
    base.enableBonusProductSelection();
    base.showMoreBonusProducts();
    base.addBonusProductsToCart();
    base.focusChooseBonusProductModal();
    base.trapChooseBonusProductModalFocus();
    base.onClosingChooseBonusProductModal();
};
