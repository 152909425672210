'use strict';

module.exports = function () {
    $('.sold-bar').each(function () {
        var elem = $(this);
        var $curr = 0;
        var $indi = elem.find('.item-indicator');
        var $total = $indi.data('circle');

        if ($curr <= $total) {
            if ($total === 1) {
                $indi.css('opacity', '1');
                $indi.attr('transform', 'rotate(-90 57.5 57.5)');
            }
            if ($total > 0 && $total < 30) {
                $indi.addClass('start-campaign'); 
            } else if ($total > 29 && $total < 60) {
                $indi.addClass('medium-campaign');
            } else if ($total > 59) {
                $indi.addClass('closing-campaign');
                // eslint-disable-next-line no-unused-expressions
                $('.closing-soon__item').length ? $indi.addClass('blink') : '';
            }
            $indi.css({ width: $total + '%' });
        } else if ($curr === 101) {
            $indi.next().css('opacity', '0');
        }
    });
};
