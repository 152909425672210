window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');
$(document).ready(function () {
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/materialize'));
    processInclude(require('./components/cookieWarning'));
    processInclude(require('base/components/menu'));
    processInclude(require('base/components/consentTracking'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/miniAccount'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('./product/bundleTile'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./components/shareCampaign'));
    
    processInclude(require('jquery-validation/dist/jquery.validate.min.js'));
    processInclude(require('./globalValidation'));
    processInclude(require('./components/soldbar'));
    processInclude(require('./components/onlyNumbers'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/timeLimted'));
    processInclude(require('./components/pageLoader'));
    // processInclude(require('./gtm'));
    processInclude(require('./fbpixels'));
    processInclude(require('./product/quickView'));
    processInclude(require('./product/productQuickView'));
    processInclude(require('./product/productCarousel'));
    processInclude(require('./components/shareCampaign'));
    processInclude(require('./components/currencySelector'));
    processInclude(require('./braze'));
    processInclude(require('./availabilityDial'));
    processInclude(require('./components/newRegisterPopout'));
    processInclude(require('./analytics'));
    processInclude(require('./components/sessionRedirect'));
    $('body').on('click', '.js-close-in-popup', function () {
        $('.notEnoughIpoints').hide();
    });
});
require('base/thirdParty/bootstrap');
require('slick-carousel');
require('jquery-knob');
require('base/components/spinner');
require('./components/tabs');
require('./components/lozad');
