'use strict';
var base = require('./base');
var focusHelper = require('../components/focus');
var mobileDetailsTab = require('./mobileDetailsTab');

/**
 * Generates the modal window on the first call.
 *
 */
function getModalHtmlElement() {
    if ($('#productViewBox').length !== 0) {
        $('#productViewBox').remove();
    }


    var htmlString = '<!-- Modal -->'
        + '<div class="modalBox productQuickView" id="productViewBox" role="dialog">'
        + '<div class="toCenterModalBox"><div class="modalBoxWrapper product-view-dialog">'
        + '<div class="modalContent">'
        + '<div class="modal-body"><div class="user-action"><a href="javascript:;" type="button" class="close pull-right" data-dismiss="modal"><img src="/on/demandware.static/Sites-Idealz-ae-Site/-/default/dw2fa338ee/images/newdesktop/arrow-down-sign-to-navigate@2x.png" alt=""></a></div><div class="product-view-image"></div></div>'
        + '<div class="modal-footer"><p></p></div>'
        + '</div>'
        + '</div>'
        + '</div></div>';
    $('body').append(htmlString);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * Parse HTML code in Ajax response
 *
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 */
function parseHtml(html) {
    var $html = $('<div>').append($.parseHTML(html));

    var body = $html.find('.product-quickview');
    var footer = $html.find('.modal-footer').children();

    return { body: body, footer: footer };
}

/**
 * replaces the content in the modal window on for the selected product variation.
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 */
function fillModalElement(image, description) {
    $('.modal-body').spinner().start();

    $('.modal-body').spinner().start();
    var parsedHtml = parseHtml(description);
    $('.modal-body p').empty();
    $('.modal-body .product-view-image').empty();
    $('.modal-body p').html(description);
    $('.modal-footer p').html(description);
    $('.modal-body .product-view-image').html(image);
    $('#productViewBox').modal('show');

    $.spinner().stop();
}

module.exports = {
    showQuickview: function () {
        $('body').on('click', '.quickproductview', function (e) {
            e.preventDefault();
            var productImage = $(this).data('product-image');
            var productDescription = $(this).data('product-deescription');
            var productTitle = $(this).find('h3').text();
            $(e.target).trigger('quickproductview:show');
            productImage = '<img src="' + productImage + '" />';
            
            getModalHtmlElement();
            fillModalElement(productImage, productDescription);
            $(document).trigger('GMT:productOpen', productTitle);
        });
    },
    focusQuickview: function () {
        $('body').on('shown.bs.modal', '#productViewBox', function () {
            $('#productViewBox .close').focus();
        });
    },
    trapQuickviewFocus: function () {
        $('body').on('keydown', '#productViewBox', function (e) {
            var focusParams = {
                event: e,
                containerSelector: '#productViewBox',
                firstElementSelector: '.full-pdp-link',
                lastElementSelector: '.add-to-cart-global',
                nextToLastElementSelector: '.modal-footer .quantity-select'
            };
            focusHelper.setTabNextFocus(focusParams);
        });
    },
    colorAttribute: base.colorAttribute,
    selectAttribute: base.selectAttribute,
    removeBonusProduct: base.removeBonusProduct,
    selectBonusProduct: base.selectBonusProduct,
    enableBonusProductSelection: base.enableBonusProductSelection,
    showMoreBonusProducts: base.showMoreBonusProducts,
    addBonusProductsToCart: base.addBonusProductsToCart,
    availability: base.availability,
    addToCart: base.addToCart,
    showSpinner: function () {
        $('body').on('product:beforeAddToCart', function (e, data) {
            $(data).closest('.modal-content').spinner().start();
        });
    },
    // hideDialog: function () {
    //     $('body').on('product:afterAddToCart', function () {
    //         $('#quickViewModal').modal('hide');
    //     });
    // },
    beforeUpdateAttribute: function () {
        $('body').on('product:beforeAttributeSelect', function () {
            $('.modal.show .modal-content').spinner().start();
        });
    },
    updateAttribute: function () {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.modal.show .product-quickview>.bundle-items').length) {
                $('.modal.show').find(response.container).data('pid', response.data.product.id);
                $('.modal.show').find(response.container)
                    .find('.product-id').text(response.data.product.id);
            } else if ($('.set-items').length) {
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.modal.show .product-quickview').data('pid', response.data.product.id);
                $('.modal.show .full-pdp-link')
                    .attr('href', response.data.product.selectedProductUrl);
            }
        });
    },
    updateAddToCart: function () {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr('disabled',
                (!response.product.readyToOrder || !response.product.available));

            // update global add to cart (single products, bundles)
            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            $('.add-to-cart-global', dialog).attr('disabled',
                !$('.global-availability', dialog).data('ready-to-order')
                || !$('.global-availability', dialog).data('available')
            );
        });
    },
    updateAvailability: function () {
        $('body').on('product:updateAvailability', function (e, response) {
            // bundle individual products
            $('.product-availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available)
                .find('.availability-msg')
                .empty()
                .html(response.message);


            var dialog = $(response.$productContainer)
                .closest('.quick-view-dialog');

            if ($('.product-availability', dialog).length) {
                // bundle all products
                var allAvailable = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('available'); });

                var allReady = $('.product-availability', dialog).toArray()
                    .every(function (item) { return $(item).data('ready-to-order'); });

                $('.global-availability', dialog)
                    .data('ready-to-order', allReady)
                    .data('available', allAvailable);

                $('.global-availability .availability-msg', dialog).empty()
                    .html(allReady ? response.message : response.resources.info_selectforstock);
            } else {
                // single product
                $('.global-availability', dialog)
                    .data('ready-to-order', response.product.readyToOrder)
                    .data('available', response.product.available)
                    .find('.availability-msg')
                    .empty()
                    .html(response.message);
            }
        });
    }
};
