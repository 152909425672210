'use strict';

$(document).ready(function () {
    $('#winners, #comingSoon, #soldOut').removeClass('active').removeClass('show');
    $('#root-tab').on('click', function () {
        $('.tab-pane').addClass('active').addClass('show');
        $('[data-toggle="tab"]').parent().removeClass('active');
        $('#winners, #comingSoon, #soldOut').removeClass('active').removeClass('show');
    });
    $('.nav-link').not('#root-tab').on('click', function () {
        $('.tab-pane').not(this.hash).removeClass('active').removeClass('show');
    });
});
