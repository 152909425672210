'use strict';


  
$(document).on('click', '.share-trigger', function () {
    $(this).toggleClass('active');
    $(this).parent().toggleClass('active');
});
    
$(document).on('click', '.additional-winner-title', function () {
    $(this).parent().find('.additionalWinner-Box').fadeIn();
});


$(document).mouseup(function(e) 
{
    var container = $(".additional-prize-info-box");

    // if the target of the click isn't the container nor a descendant of the container
    if (!container.is(e.target) && container.has(e.target).length === 0) 
    {
        container.find('.additionalWinner-Box').fadeOut();
    }
});