'use strict';

import Swiper from 'swiper/bundle';



const campaignSlider = new Swiper('.main-product-banner .swiper-container', {
    direction: 'horizontal',
    spaceBetween: 20,
    slidesPerView: 4,
    slidesOffsetBefore: 0,
    slidesOffsetAfter: 0,
    freeMode: false,
    preloadImages: false,
    watchSlidesVisibility: true,
    slidesPerGroup: 4,
    navigation: {
        nextEl: '.main-product-banner .swiper-button-next',
        prevEl: '.main-product-banner .swiper-button-prev',
    },
    // Enable lazy loading
});

