/* eslint-disable new-cap */
'use strict';

var $isUserLoggedIn = $('#isUserLoggedIn').val();
if ($isUserLoggedIn === 'false') {
    if ($('.new-register-popout').length > 0) {
        var container = $('.new-register-popout-wrapper');
        var $xcluded = $('.main-banner-item a, .cta-offers, .closing-soon a, .newCart-indicator, #main-page-slider a, .quickview, .campaign-box-ui.closing-soon__item .campaign-action, .commonBanner a, .cart-indicator');

        $('.minicartId').hide();

        $xcluded.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            // $('.new-register-popout').addClass('show');

            window.location.href = $('.new-register-popout').find('.login').attr('href');
        });

        $(document).on('livecampaign:loaded', function () {
            $('.idealz-credit-campaign-box .campaign-image .swiper-slide a, .cta-offers, .add-to-cart-area, #main-campagin-area .big-box .campaign-box-single .campaign-action, .quickview').on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();
                // $('.new-register-popout').addClass('show');
                window.location.href = $('.new-register-popout').find('.login').attr('href');
            });
        });

        $('.new-register-popout').on('click', function (e) {
            if (container.parent().hasClass('show') && !container.is(e.target) && container.has(e.target).length === 0) {
                $('.new-register-popout').removeClass('show');
            }
        });
    }
}
