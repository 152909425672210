/* eslint-disable no-undef */
'use strict';
// var COOKIE = require('./components/cookies');
var CryptoJS = require('crypto-js');

var currencySelected = $('.page').attr('data-current-currency');
var languageSelected = $('.page').attr('data-current-lang');

var SNAPCHAT_EVENT_NAME = {
    purchase: 'PURCHASE',
    purchaseFailed: 'PURCHASE_FAILED',
    save: 'SAVE',
    startCheckout: 'START_CHECKOUT',
    addCart: 'ADD_CART',
    viewContent: 'VIEW_CONTENT',
    addBilling: 'ADD_BILLING',
    signup: 'SIGN_UP',
    pageView: 'PAGE_VIEW',
    login: 'LOGIN',
    share: 'SHARE',
    addToWishlist: 'ADD_TO_WISHLIST',
    spentCredits: 'SPENT_CREDITS',
    removeFromCart: 'CUSTOM_EVENT_1',
    removeFromWishlist: 'CUSTOM_EVENT_2',
    logout: 'CUSTOM_EVENT_3',
    addPromo: 'ADD_PROMO',
    signupFailed: 'SIGN_UP_FAILED',
    promoFailed: 'PROMO_FAILED'
};
var snapChatID = $('.snapchat-analytics-id').data('id');
var customerEmail =
    typeof GTM_CUSTOMER !== 'undefined' &&
    GTM_CUSTOMER &&
    GTM_CUSTOMER.customerEmail
        ? GTM_CUSTOMER.customerEmail
        : null;

var removePlusSign = function (phoneText) {
    return phoneText ? phoneText.replace('+', '') : phoneText;
};
var initUser = function (customer) {
    if (typeof snaptr !== 'undefined' && snaptr && snapChatID && snapChatID !== 'null' && customer && customer.customerEmail) {
        snaptr('init', snapChatID, {
            user_email: customer.customerEmail,
            user_phone_number: removePlusSign(customer.phone),
            firstname: customer.firstName,
            lastname: customer.lastName,
            geo_country: customer.country,
            user_hashed_email: CryptoJS.SHA256(customer.customerEmail.toLowerCase()).toString(),
            user_hashed_phone_number: CryptoJS.SHA256(removePlusSign(customer.phone)).toString()
        });
    }
};


var mapItem = function (param) {
    if (param && param.items && param.items.length) {
        return param.items.map(function (item) {
            return {
                price: item.price,
                currency: param.currency,
                description: item.name,
                item_ids: [item.id],
                brands: [item.brand]
            };
        });
    }
    return [];
};

var mapPurchaseOrder = function (param) {
    if (param && param.items && param.items.length > 0) {
        return Object.assign({}, param, {
            number_items: param.items.length,
            item_ids: param.items.map(function (item) {
                return item.item_id;
            }),
            currency: currencySelected,
            price: param.order_total,
            success: 1
        });
    }
    return null;
};

var mapInitiateCheckout = function (param) {
    if (param && param.items && param.items.length > 0) {
        return Object.assign({}, param, {
            number_items: param.items.length,
            item_ids: param.items.map(function (item) {
                return item.item_id;
            }),
            currency: currencySelected,
            price: param.order_total
        });
    }
    return null;
};

var trackUserEvents = function (eventName, param) {
    if (typeof snaptr !== 'undefined' && snaptr) {
        switch (eventName) {
            case SNAPCHAT_EVENT_NAME.addCart:
                var mapAddCart = mapItem(param);
                snaptr('track', SNAPCHAT_EVENT_NAME.addCart, mapAddCart[0]);
                break;
            case SNAPCHAT_EVENT_NAME.removeFromCart:
                var mapRemoveCart = mapItem(param);
                snaptr(
                    'track',
                    SNAPCHAT_EVENT_NAME.removeFromCart,
                    Object.assign(
                        {},
                        { event_tag: 'REMOVE_FROM_CART' },
                        mapRemoveCart[0]
                    )
                );
                break;
            case SNAPCHAT_EVENT_NAME.addToWishlist:
                var mapWishlist = mapItem(param);
                snaptr(
                    'track',
                    SNAPCHAT_EVENT_NAME.addToWishlist,
                    mapWishlist[0]
                );
                break;
            case SNAPCHAT_EVENT_NAME.removeFromWishlist:
                var removeFromWishlist = mapItem(param);
                snaptr(
                    'track',
                    SNAPCHAT_EVENT_NAME.removeFromWishlist,
                    Object.assign(
                        {},
                        { event_tag: 'REMOVE_FROM_WISHLIST' },
                        removeFromWishlist[0]
                    )
                );
                break;
            case SNAPCHAT_EVENT_NAME.login:
                initUser(Object.assign({}, param, { customerEmail: param.email, phone: param.mobileNumber }));
                snaptr('track', SNAPCHAT_EVENT_NAME.login, param);
                break;
            case SNAPCHAT_EVENT_NAME.logout:
                snaptr('track', SNAPCHAT_EVENT_NAME.logout, Object.assign(
                    {},
                    { event_tag: 'LOGOUT' }, param));
                break;
            case SNAPCHAT_EVENT_NAME.signup:
                var signupSuccessParam = Object.assign({}, param, {
                    success: 1
                });
                snaptr('track', SNAPCHAT_EVENT_NAME.signup, signupSuccessParam);
                break;
            case SNAPCHAT_EVENT_NAME.signupFailed:
                var signupFailedParam = Object.assign({}, param, {
                    success: 0
                });
                snaptr('track', SNAPCHAT_EVENT_NAME.signup, signupFailedParam);
                break;
            case SNAPCHAT_EVENT_NAME.addPromo:
                var promoAddParams = Object.assign({}, param, {
                    success: 1
                });
                snaptr('track', SNAPCHAT_EVENT_NAME.addPromo, promoAddParams);
                break;
            case SNAPCHAT_EVENT_NAME.promoFailed:
                var promoFailedParams = Object.assign({}, param, {
                    success: 0
                });
                snaptr(
                    'track',
                    SNAPCHAT_EVENT_NAME.addPromo,
                    promoFailedParams
                );
                break;
            case SNAPCHAT_EVENT_NAME.startCheckout:
                var checkoutParam = mapInitiateCheckout(param);
                snaptr(
                    'track',
                    SNAPCHAT_EVENT_NAME.startCheckout,
                    checkoutParam
                );
                break;
            case SNAPCHAT_EVENT_NAME.spentCredits:
                snaptr('track', SNAPCHAT_EVENT_NAME.spentCredits, param);
                break;
            case SNAPCHAT_EVENT_NAME.purchase:
                var purchaseMap = mapPurchaseOrder(param);
                if (purchaseMap) {
                    snaptr('track', SNAPCHAT_EVENT_NAME.purchase, purchaseMap);
                }
                break;
            case SNAPCHAT_EVENT_NAME.viewContent:
                var mapViewContent = mapItem(param);
                snaptr(
                    'track',
                    SNAPCHAT_EVENT_NAME.viewContent,
                    mapViewContent
                );
                break;
            case SNAPCHAT_EVENT_NAME.share:
                snaptr('track', SNAPCHAT_EVENT_NAME.share, {
                    description: param.description
                });
                break;
            case SNAPCHAT_EVENT_NAME.pageView:
                snaptr('track', SNAPCHAT_EVENT_NAME.pageView);
                break;
            default:
                break;
        }
    }
};

if (
    typeof GTM_CUSTOMER !== 'undefined' &&
        GTM_CUSTOMER &&
        GTM_CUSTOMER.customerNo
) {
    initUser(GTM_CUSTOMER);
    trackUserEvents(SNAPCHAT_EVENT_NAME.pageView);
}


var gtagElems = $('span[data-gtag-event]:not(.begin_checkout)');
if (gtagElems.length > 0) {
    gtagElems.each(function () {
        var gtagElem = $(this);
        var eventName = gtagElem.data('gtag-event');
        var param = gtagElem.attr('data-gtag-param');
        param = JSON.parse(param);
        if (eventName && eventName.toLowerCase() === 'purchase' && param) {
            var newItem = {};
            var newCart = [];

            param.items.map(function (item, index) {
                newItem.item_id = item.id;
                newItem.price = item.price;
                newItem.currency = item.currencyCode;
                newItem.product_name = item.name;
                newItem.item_brand = item.brand;
                newItem.index = index;
                newItem.item_category = item.category;
                newItem.quantity = item.quantity;

                newCart.push(newItem);
            });

            var newParam = {
                transaction_id: param.transaction_id,
                value: param.value,
                aed_value: param.aed_value,
                order_total: param.order_total,
                discount: param.discount,
                tax: param.tax,
                shipping: param.shipping,
                currency: param.currency,
                coupon: param.coupon,
                aed_revenue_before_discount: param.aed_revenue_before_discount,
                aed_revenue_after_discount: param.aed_revenue_after_discount,
                new_customer: param.new_customer,
                payment_method: param.payment_method,
                aed_wallet_value: param.aed_wallet_value,
                used_wallet_balance: param.used_wallet_balance,
                items: newCart
            };

            trackUserEvents(SNAPCHAT_EVENT_NAME.purchase, newParam);
        }
    });
    if ($('.mobileSpecialPaymentError.errorFound').length > 0) {
        trackUserEvents(SNAPCHAT_EVENT_NAME.purchase, {
            error: $(this).find('span').text(),
            success: 0
        });
    }
}

module.exports = {
    initUser: initUser,
    trackUserEvents: trackUserEvents,
    SNAPCHAT_EVENT_NAME: SNAPCHAT_EVENT_NAME
};