import lozad from 'lozad'

const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();


$(document).on('live:initDial', function (e) {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});

$(document).on('live:winnerLoaded', function (e) {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});

$(document).on('winner:reTrigger', function (e) {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});

$(document).on('live:soldoutLoaded', function (e) {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});

$(document).on('live:drawloaded', function (e) {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});

$(document).on('quickView:init', function (e) {
    const observer = lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
});