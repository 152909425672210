'use strict';
var isMobile = !($(window).width() >= 768);

module.exports = {
    init: function () {
        $('.js-currency-selector a').on('click', function () {
            var action = $('.page').data('action');
            var localeCode = $(this).data('locale');
            var localeCurrencyCode = $(this).data('currencycode');
            var queryString = $('.page').data('querystring');
            var url = $('.js-currency-selector').data('url');
            url += '?localeCurrencyCode=' + localeCurrencyCode;

            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {
                    code: localeCode,
                    queryString: queryString,
                    CurrencyCode: localeCurrencyCode,
                    action: action
                },
                success: function (response) {
                    $.spinner().stop();
                    var gTagData = {
                        localeCurrencyCode: localeCurrencyCode
                    };
                    $(document).trigger('currency:success', gTagData);
                    if (response && response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });

        $('.js-currency-selector').on('click', function () {
            $(this)
                .addClass('show')
                .children('.dropdown-menu')
                .addClass('show');
        });

        $(document).mouseup(function (e) {
            if (!$('.js-currency-selector').is(e.target) // if the target of the click isn't the container...
            && $('.js-currency-selector').has(e.target).length === 0) {
                $('.js-currency-selector')
                    .removeClass('show')
                    .children('.dropdown-menu')
                    .removeClass('show');
            }
        });

        if (isMobile) {
            $('#MobileCurrency .done-btn').click(function () {
                var $cur = $('#MobileCurrency');
                var $curText = $cur.attr('data-selected');
                var $preText = $cur.attr('data-prev-selected');
                if (($cur.hasClass('active')) && ($curText !== $preText)) {
                    var action = $('.page').data('action');
                    var localeCode = $cur.find('.active').data('locale');
                    var localeCurrencyCode = $cur.find('.active').data('currencycode');

                    var queryString = $('.page').data('querystring');
                    var url = $('.js-currency-selector').data('url');
                    $.ajax({
                        url: url,
                        type: 'get',
                        dataType: 'json',
                        data: {
                            code: localeCode,
                            queryString: queryString,
                            CurrencyCode: localeCurrencyCode,
                            action: action
                        },
                        success: function (response) {
                            $.spinner().stop();
                            var gTagData = {
                                localeCurrencyCode: localeCurrencyCode
                            };
                            $(document).trigger('currency:success', gTagData);
                            if (response && response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                }
                $cur.removeClass('active');
                $('html, body').removeClass('removeScroll');
            });
        } else {
            $('#currencySelector').on('change', function () {
                var $cur = $('#currencySelector');
                var localeCodeText = $('#currencySelector option:selected').text();
                $cur.attr('data-selected', localeCodeText);
                var $curText = $cur.attr('data-selected');
                var $preText = $cur.attr('data-prev-selected');
                    
                if ($curText !== $preText) {
                    var action = $('.page').data('action');
                    var localeCode = $('#currencySelector option:selected').data('locale');
                    var localeCurrencyCode = $('#currencySelector option:selected').val();

                    var queryString = $('.page').data('querystring');
                    var url = $cur.data('url');
                    $.ajax({
                        url: url,
                        type: 'get',
                        dataType: 'json',
                        data: {
                            code: localeCode,
                            queryString: queryString,
                            CurrencyCode: localeCurrencyCode,
                            action: action
                        },
                        success: function (response) {
                            $.spinner().stop();
                            var gTagData = {
                                localeCurrencyCode: localeCurrencyCode
                            };
                            $(document).trigger('currency:success', gTagData);
                            if (response && response.redirectUrl) {
                                window.location.href = response.redirectUrl;
                            }
                        },
                        error: function () {
                            $.spinner().stop();
                        }
                    });
                }
            });
        }
    }
};
