'use strict';
var CURRENT_SESSION_ID_KEY = 'sid';
var PREVIOUS_SESSION_ID_KEY = 'previousSid';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

module.exports = function () {
    var $content = $('.js-cookie-warning-content');
    var $container = $('.js-cookie-warning-container');

    // If content asset is not defined or offline, don't go further
    if ($content.find('.content-asset').length === 0) {
        $container.addClass('d-none');
        return;
    }

    // Get current and previous session id values
    var previousSessionID = window.localStorage.getItem(PREVIOUS_SESSION_ID_KEY);
    var currentSessionID = getCookie(CURRENT_SESSION_ID_KEY);

    if (!previousSessionID && currentSessionID) {
        // Show the cookie warning
        $container.removeClass('d-none');
    } else if (previousSessionID && previousSessionID === currentSessionID) {
        // Hide the cookie alert if user is in the same session
        $container.addClass('d-none');
    } else {
        // Clear the previousSessionID from localStorage
        // when user session is changed or expired
        previousSessionID = '';
        window.localStorage.removeItem(PREVIOUS_SESSION_ID_KEY);
    }

    // Attach close event
    $('.js-cookie-cancel-btn').on('click', function () {
        // Set the previousSessionID to currentSessionID
        if (currentSessionID) {
            window.localStorage.setItem(PREVIOUS_SESSION_ID_KEY, currentSessionID);
        }

        // Hide the cookie warning
        $container.addClass('d-none');
    });
};
